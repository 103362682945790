<template>
  <v-data-table
    :headers="headers"
    :items="events"
    class="elevation-1"
    :class="{'no-head': $vuetify.breakpoint.xsOnly}"
    :options.sync="pagination"
    item-key="teamId"
    :no-data-text="noDataText"
    :dense="listOnly"
  >
    <template v-slot:progress>
      <v-progress-linear :indeterminate="true" color="color3"></v-progress-linear>
    </template>
    <template v-slot:[`item.actions`]={item}>
      <v-tooltip top>
        <span>View Tournament Schedule</span>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="color3 color3Text--text"
            :to="`/tournament/${item.id}/team/${item.teamId}`"
            @click.stop="dialog = true"
            text icon small
          >
            <v-icon>fas fa-clipboard-list</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <template v-slot:[`item.points`]={item}>
      <table class="pt caption">
        <template v-for="p in item.points">
          <tr :key="`${p.short}-${item.id}`" v-if="!ps || (ps === p.short.toLowerCase())">
            <td class="text-right pa-0 text-truncate" :class="{used: p.used}">{{p.short}}: {{p.total | fixed1OrInt}}</td>
          </tr>
        </template>
      </table>
    </template>
    <template v-slot:[`item.finish`]={item}>
      {{item.finish | ordinal}}
    </template>
    <template v-slot:[`item.date`]={item}>
      {{item.date | shortDate}}
    </template>
    <template v-slot:[`item.partner`]={item}>
      <template v-if="item.props.includes('lineups')">
        {{ item.teamName }}
      </template>
      <template v-else>
        <template v-for="(partner, i) in item.partners">
          {{!$vuetify.breakpoint.xsOnly && i > 0 ? ', ' : ''}}
          <player-link :player="partner" :key="`${i}-${partner.id}`"></player-link>
          <br
            v-if="$vuetify.breakpoint.xsOnly"
            :key="`${i}-${partner.id}-br`"
          >
        </template>
      </template>
    </template>
    <template v-slot:[`item.tournament`]={item}>
      <div v-if="listOnly" >{{item.tournament}}</div>
      <router-link v-else class="color3--text" :to="{name: 'tournament-brochure', params: {tournamentId: item.id}}">{{item.tournament}}</router-link>
    </template>
  </v-data-table>
</template>

<script>
import PlayerLink from '@/components/Player/PlayerLink'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: ['tournaments', 'upcoming', 'noDataText', 'listOnly', 'ps'],
  data () {
    return {
      pagination: {
        sortBy: ['unix'],
        sortDesc: [!this.upcoming]
      }
    }
  },
  computed: {
    ...mapGetters(['noPoints']),
    events () {
      return this.tournaments.length > 0 ? this.tournaments.map(m => {
        if (m.tags && m.tags.includes('altDiv-')) {
          console.log(m.tags)
          const tags = m.tags.split(',')
          const altDivTag = tags.find(f => f.startsWith('altDiv-'))
          if (altDivTag) {
            m.division = altDivTag.replace('altDiv-', '').replace('_', ' ')
            m.finish = m.bFinish
          }
        }
        return {
          id: m.id,
          date: m.date,
          tournament: m.tournament,
          division: m.division,
          partners: m.partners,
          finish: m.finish > 999 ? null : m.finish,
          points: m.points,
          unix: moment(m.date).unix(),
          teamId: m.teamId,
          props: m._Props2 ? m._Props2.split(',') : [],
          teamName: m.teamName
        }
      }) : []
    },
    headers () {
      return [
        { text: 'Finish', value: 'finish', align: 'center', sortable: false, show: !this.upcoming },
        { text: 'Date', value: 'date', align: 'left', sortable: false, show: true },
        { text: 'Tournament', value: 'tournament', align: 'left', sortable: false, show: true },
        { text: 'Division', value: 'division', align: 'left', sortable: false, show: true },
        { text: this.upcoming ? 'Playing With' : 'Played With', value: 'partner', align: 'left', sortable: false, show: true },
        { text: 'Points', value: 'points', align: 'right', sortable: true, show: !this.upcoming && !this.noPoints && !this.listOnly },
        { text: '', value: 'actions', align: 'right', sortable: false, show: !this.listOnly }
      ].filter(f => f.show)
    }
  },
  methods: {
    gotoTournament (id) {
      const route = { name: 'tournament-brochure', params: { tournamentId: id } }
      this.$router.push(route)
    }
  },
  components: {
    PlayerLink
  }
}
</script>

<style scoped>
.link {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
.pt {
  width: 100% !important;
}
.pt td {
  padding: 0 !important;
  height: auto !important;
  border: none !important;
}
.used,
.used td {
  font-weight: 800;
}
.gold {
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important;
}
.no-head >>> thead {
  display: none;
}
</style>
