var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",class:{'no-head': _vm.$vuetify.breakpoint.xsOnly},attrs:{"headers":_vm.headers,"items":_vm.events,"options":_vm.pagination,"item-key":"teamId","no-data-text":_vm.noDataText,"dense":_vm.listOnly},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":true,"color":"color3"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"color3 color3Text--text","to":("/tournament/" + (item.id) + "/team/" + (item.teamId)),"text":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},on),[_c('v-icon',[_vm._v("fas fa-clipboard-list")])],1)]}}],null,true)},[_c('span',[_vm._v("View Tournament Schedule")])])]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_c('table',{staticClass:"pt caption"},[_vm._l((item.points),function(p){return [(!_vm.ps || (_vm.ps === p.short.toLowerCase()))?_c('tr',{key:((p.short) + "-" + (item.id))},[_c('td',{staticClass:"text-right pa-0 text-truncate",class:{used: p.used}},[_vm._v(_vm._s(p.short)+": "+_vm._s(_vm._f("fixed1OrInt")(p.total)))])]):_vm._e()]})],2)]}},{key:"item.finish",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ordinal")(item.finish))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDate")(item.date))+" ")]}},{key:"item.partner",fn:function(ref){
var item = ref.item;
return [(item.props.includes('lineups'))?[_vm._v(" "+_vm._s(item.teamName)+" ")]:[_vm._l((item.partners),function(partner,i){return [_vm._v(" "+_vm._s(!_vm.$vuetify.breakpoint.xsOnly && i > 0 ? ', ' : '')+" "),_c('player-link',{key:(i + "-" + (partner.id)),attrs:{"player":partner}}),(_vm.$vuetify.breakpoint.xsOnly)?_c('br',{key:(i + "-" + (partner.id) + "-br")}):_vm._e()]})]]}},{key:"item.tournament",fn:function(ref){
var item = ref.item;
return [(_vm.listOnly)?_c('div',[_vm._v(_vm._s(item.tournament))]):_c('router-link',{staticClass:"color3--text",attrs:{"to":{name: 'tournament-brochure', params: {tournamentId: item.id}}}},[_vm._v(_vm._s(item.tournament))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }